<template>
  <v-card class="pa-4" :class="darkmode || 'grey lighten-5'" flat>
    <div class="mb-5 font-weight-bold" style="cursor: pointer; width: 100px">
      <v-btn @click="$router.go(-1)" depressed>
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("app.back") }}
      </v-btn>
    </div>

    <v-speed-dial
      v-model="fab"
      transition="slide-y-reverse-transition"
      direction="top"
      class="fab"
      style="position: fixed; bottom: 20px; right: 20px; z-index: 3"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="primary" dark fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            fab
            dark
            small
            color="pink"
            @click="
              $router.push(
                `/academic/question-bank-etest/${$route.params.id}/${dataTable.header.lesson}`
              )
            "
          >
            <v-icon>mdi-folder-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("e_test.question_bank") }}</span>
      </v-tooltip>

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            fab
            dark
            small
            color="orange darken-1"
            @click="toggleAddManual"
          >
            <v-icon>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("app.add") }} {{ $t("e_test.question") }}</span>
      </v-tooltip>

      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            fab
            dark
            small
            color="yellow lighten-2"
            @click="toggleDialogCopy"
          >
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("e_test.copy_to") }}</span>
      </v-tooltip>
    </v-speed-dial>

    <HeaderInfo
      :info="dataTable"
      :buttonName="infoButton().name"
      showButton
      :icon="infoButton().icon"
      @actionButton="togglePublish()"
    />
    <div class="d-flex align-center">
      <div>
        <v-icon class="mr-2" color="orange" large>mdi-star</v-icon>
      </div>
      {{ $t("e_test.the_main_question") }}
      <div>
        <v-icon class="mx-2" color="grey" large>mdi-star</v-icon>
      </div>
      {{ $t("e_test.backup_question") }}
      <v-spacer />
      {{ $t("e_test.total_questions") }} =
      <div class="font-weight-bold ml-1 mr-10">
        {{ dataTable.body.data.length }}
      </div>
      {{ $t("e_test.total_weight") }} =
      <div class="font-weight-bold ml-1">
        {{ dataTable.header.total_point }}
      </div>
    </div>
    <Pagination
      :to="dataTable.body.to"
      :total="dataTable.body.total"
      :currentPage="table.page"
      :nextPage="dataTable.body.next_page_url"
      :prevPage="dataTable.body.prev_page_url"
      :handler="paginateLimit"
      @next="arrowPage"
      @back="arrowPage"
      @search="handleSearch"
    />
    <div class="d-flex justify-end mr-3 mb-3">
      <v-tooltip v-if="multipleQuestion.length > 0" left>
        <template #activator="{ on }">
          <v-btn v-on="on" icon dark class="error" @click="dialogConfirm">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("e_test.cancel_use_question") }}</span>
      </v-tooltip>
    </div>
    <v-data-table
      v-model="multipleQuestion"
      :headers="tableHeaders"
      :items="dataTable.body.data"
      :items-per-page="table.limit"
      :page.sync="table.page"
      :loading="table.loading"
      show-select
      item-key="id"
      show-expand
      hide-default-footer
      disable-pagination
      class="elevation-0"
    >
      <template #item.data-table-expand="{ item, expand, isExpanded }">
        <div class="d-flex flex-row">
          <v-btn
            icon
            small
            class="mr-2 primary"
            dark
            @click="expand(!isExpanded)"
          >
            <v-icon small>mdi-chevron-down</v-icon>
          </v-btn>
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn
                v-on="on"
                :disabled="multipleQuestion.length != 0"
                icon
                dark
                small
                class="error"
                @click="dialogConfirm(item)"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("e_test.cancel_use_question") }}</span>
          </v-tooltip>
        </div>
      </template>
      <template #item.kd="{ item }">
        <span v-if="item.kd">
          {{ getKD(item.kd) }}
        </span>
        <v-edit-dialog
          v-else
          :cancel-text="$t('app.cancel')"
          :save-text="$t('app.save')"
          :return-value.sync="modelKD"
          large
          transition="fade-transition"
          @save="saveKD(item.question_bank)"
        >
          <v-btn :disabled="table.loading" color="error" text small>
            {{ $t("e_test.msg.kd_empty") }}
          </v-btn>
          <template #input>
            <v-autocomplete
              v-model="modelKD"
              :items="dataKD"
              :item-text="item => getKD(item.id)"
              item-value="id"
              single-line
              class="caption"
            />
          </template>
        </v-edit-dialog>
      </template>
      <template #item.type="{ item }">
        {{ item.type === "pg" ? $t("e_test.multiple_choice") : "Essay" }}
      </template>
      <template #item.number="{ item }">
        {{ dataTable.body.data.indexOf(item) + dataTable.body.from - 1 + 1 }}
      </template>
      <template #item.question="{ item }">
        <span v-html="truncateHtml(item.question)" />
      </template>
      <template #item.point="{ item }">
        <v-edit-dialog
          :return-value.sync="item.point"
          large
          persistent
          @save="savePoint(item.id)"
          @close="resetWeight()"
        >
          <v-btn color="primary" text>{{ item.point }}</v-btn>
          <template v-slot:input>
            <v-text-field
              ref="weight"
              v-model="point"
              :rules="[
                v => !!v || $t('app.required'),
                v => Number(v) <= 100 || $t('e_test.msg.validate_weight'),
                v => Math.sign(v) === 1 || $t('e_test.msg.validate_minus')
              ]"
              type="number"
              min="0"
              single-line
            />
          </template>
        </v-edit-dialog>
      </template>
      <template #item.main="{ item }">
        <div>
          <v-btn
            :disabled="disableStar"
            depressed
            icon
            @click="questionPriority(item)"
          >
            <v-icon
              :color="item.main == 1 ? 'orange' : 'grey'"
              class="cursor-star"
              >mdi-star</v-icon
            >
          </v-btn>
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-5 pl-16">
          <div
            class="question"
            v-html="item.question"
            @click="viewImage($event)"
          />

          <!-- start question file -->
          <v-row v-if="item.question_file[0]">
            <v-col v-if="item.question_file.length === 1">
              <v-img
                v-if="validateImage(item.question_file[0])"
                :src="item.question_file[0]"
                style="cursor: pointer"
                max-height="200px"
                max-width="300px"
                @click="
                  {
                    urlFile = item.question_file[0];
                    viewFile = true;
                  }
                "
              />
              <v-chip
                v-else
                color="primary"
                @click="
                  {
                    urlFile = item.question_file[0];
                    viewFile = true;
                  }
                "
              >
                {{ item.question_file[0].replace(/^.*[\\/]/, "") }}
              </v-chip>
            </v-col>
            <div
              v-else
              v-for="file in item.question_file"
              :key="file"
              class="ma-2"
            >
              <v-chip
                @click="
                  {
                    urlFile = file;
                    viewFile = true;
                  }
                "
                color="primary"
              >
                {{ file.replace(/^.*[\\/]/, "") }}
              </v-chip>
            </div>
          </v-row>
          <!-- end question file -->

          <!-- start multiple choice -->
          <v-row>
            <v-col
              v-for="data in item.pg"
              :key="data.id"
              cols="12"
              sm="6"
              class="my-2"
            >
              <div
                :class="!data.key || 'font-weight-bold'"
                class="d-flex flex-row"
              >
                <span class="mr-2">{{ data.code }}.</span>
                <span
                  v-html="data.answer"
                  class="answer"
                  @click="viewImage($event)"
                />
              </div>
              <v-row v-if="data.answer_file[0]">
                <v-col v-if="data.answer_file.length === 1">
                  <v-img
                    v-if="validateImage(data.answer_file[0])"
                    :src="data.answer_file[0]"
                    style="cursor: pointer"
                    max-height="200px"
                    max-width="300px"
                    @click="
                      {
                        urlFile = data.answer_file[0];
                        viewFile = true;
                      }
                    "
                  />
                  <v-chip
                    v-else
                    color="primary"
                    @click="
                      {
                        urlFile = data.answer_file[0];
                        viewFile = true;
                      }
                    "
                  >
                    {{ data.answer_file[0].replace(/^.*[\\/]/, "") }}
                  </v-chip>
                </v-col>
                <div
                  v-else
                  v-for="file in data.answer_file"
                  :key="file"
                  class="ma-2"
                >
                  <v-chip
                    color="primary"
                    @click="
                      {
                        urlFile = file;
                        viewFile = true;
                      }
                    "
                  >
                    {{ file.replace(/^.*[\\/]/, "") }}
                  </v-chip>
                </div>
              </v-row>
            </v-col>
          </v-row>
          <!-- end multiple choice -->

          <!-- start answer -->
          <div v-if="item.esay">
            <v-divider class="my-3" />
            <span class="font-weight-bold">{{ $t("e_test.answer") }}</span>
            <span v-html="item.esay.key" />
            <v-row v-if="item.esay.key_file[0]">
              <v-col v-if="item.esay.key_file.length === 1">
                <v-img
                  v-if="validateImage(item.esay.key_file[0])"
                  :src="item.esay.key_file[0]"
                  style="cursor: pointer"
                  max-height="200px"
                  max-width="300px"
                  @click="
                    {
                      urlFile = item.esay.key_file[0];
                      viewFile = true;
                    }
                  "
                />
                <v-chip
                  v-else
                  color="primary"
                  @click="
                    {
                      urlFile = item.esay.key_file[0];
                      viewFile = true;
                    }
                  "
                >
                  {{ item.esay.key_file[0].replace(/^.*[\\/]/, "") }}
                </v-chip>
              </v-col>
              <div
                v-else
                v-for="file in item.esay.key_file"
                :key="file"
                class="ma-2"
              >
                <v-chip
                  @click="
                    {
                      urlFile = file;
                      viewFile = true;
                    }
                  "
                  color="primary"
                >
                  {{ file.replace(/^.*[\\/]/, "") }}
                </v-chip>
              </div>
            </v-row>
          </div>
          <!-- end answer -->

          <!-- start discussion -->
          <v-divider class="my-3" />
          <span class="font-weight-bold">{{ $t("e_test.explain") }}</span>
          <div v-if="item.type === 'pg'">{{ item.discussion || "-" }}</div>
          <div v-else>
            <span v-html="item.discussion" />
          </div>
          <!-- end discussion -->
        </td>
      </template>
    </v-data-table>
    <!-- <DialogEntry
      :open="dialogEntry"
      :info="dataTable.header"
      @toggleDialog="toggleDialogEntry"
    /> -->
    <DialogCopy
      :open="dialogCopy"
      :info="dataTable.header"
      @toggleDialog="toggleDialogCopy"
    />
    <ModalConfirm
      :close="() => (this.modelDialogConfirm = false)"
      :loading="loadingDelete"
      :isOpen="modelDialogConfirm"
      :save="removeQuestionBank"
      >{{ $t("e_test.confirm_cancel_question") }}
    </ModalConfirm>
    <AddManual
      :open="addManual"
      :info="dataTable.header"
      @toggleDialog="toggleAddManual"
    />
    <ViewImage
      :viewFile="viewFile"
      :urlFile="urlFile"
      @close="viewFile = false"
    />
    <ModalConfirm
      :title="titleModal"
      :close="() => (this.modalConfirm = false)"
      :loading="loadingConfirm"
      :isOpen="modalConfirm"
      :save="savePublish"
    >
      {{ contentModal }}
    </ModalConfirm>
    <v-dialog
      v-model="dialogPublish"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      scrollable
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title primary-title>
          {{ $t("e_test.select_question_publish") }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-radio-group v-model="radioGroup">
            <v-radio :label="$t('e_test.the_main_question')" :value="1" />
            <v-radio :label="$t('e_test.backup_question')" :value="0" />
          </v-radio-group>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="loadingPublish"
            color="primary"
            outlined
            small
            @click="dialogPublish = false"
          >
            {{ $t("app.cancel") }}
          </v-btn>
          <v-btn
            :loading="loadingPublish"
            color="gradient-primary"
            dark
            depressed
            small
            @click="savePublish()"
          >
            {{ $t("app.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  eTestLessonList,
  updateMainQuestion,
  updateWeightLocal,
  removeQuestion,
  setQuestionBankKD,
  unpublishQuestion,
  publishQuestion
} from "@/api/admin/academic/eTest";
import i18n from "@/i18n";
import store from "@/store";
import truncate from "truncate-html";
import { isImageFile } from "@/utils/validate";
import { listCompetenciesByLessonDetail } from "@/api/admin/academic/basicCompetencies";

let typingTimer;
const doneTypingInterval = 750;

export default {
  components: {
    HeaderInfo: () => import("../../components/HeaderInfo"),
    Pagination: () => import("../../components/Pagination"),
    DialogCopy: () => import("./dialog/CopyQuestion"),
    ModalConfirm: () => import("@/components/ModalConfirm"),
    AddManual: () => import("../../components/AddManual"),
    ViewImage: () => import("../../components/ViewImage")
    // DialogEntry: () => import("./dialog/EntryQuestion"),
  },
  created() {
    this.eTestLesson();
  },
  computed: {
    darkmode() {
      return store.getters.g_darkmode;
    }
  },
  data() {
    return {
      urlFile: "",
      modelKD: 0,
      viewFile: false,
      dialogPublish: false,
      loadingConfirm: false,
      radioGroup: 1,
      loadingPublish: false,
      addManual: false,
      multipleQuestion: [],
      tableHeaders: [
        { text: "#", value: "number", width: 50 },
        {
          text: i18n.t("e_test.question"),
          value: "question"
        },
        {
          text: i18n.t("e_test.basic_competencies"),
          value: "kd"
        },
        {
          text: i18n.t("e_test.created_by"),
          value: "created_name",
          width: 100
        },
        {
          text: i18n.t("e_test.question_type"),
          value: "type",
          width: 100
        },
        {
          text: i18n.t("e_test.weight"),
          value: "point",
          width: 100
        },
        {
          text: i18n.t("e_test.character"),
          value: "main",
          width: 100
        },
        {
          text: i18n.t("app.action"),
          align: "center",
          value: "data-table-expand"
        }
      ],
      // dialogEntry: false,
      dialogCopy: false,
      fab: false,
      point: null,
      table: {
        limit: 10,
        page: 1,
        search: "",
        loading: false
      },
      dataTable: {
        header: { total_point: 0 },
        body: {
          data: []
        }
      },
      disableStar: false,
      loadingDelete: false,
      modelDialogConfirm: false,
      listQuestionId: [],
      loadingKD: false,
      dataKD: [],
      contentModal: "",
      titleModal: "",
      modalConfirm: false
    };
  },
  methods: {
    togglePublish() {
      if (this.dataTable.header.status === "PUBLISH") {
        this.contentModal = this.$i18n.t("e_test.msg_publish.unpublish");
        this.titleModal =
          this.$i18n.t("app.cancel") +
          " " +
          this.$i18n.t("e_test.publish_etest");
        this.modalConfirm = !this.modalConfirm;
      } else this.dialogPublish = true;
    },
    async savePublish() {
      const msg =
        this.dataTable.header.status === "DRAFT"
          ? "e_test.msg_publish_etest"
          : "e_test.msg_unpublish_etest";
      this.loadingConfirm = true;
      this.loadingPublish = true;
      try {
        let response = {};
        if (this.dataTable.header.status === "DRAFT") {
          // question_selected (1 == main question || 0 == backup question)
          response = await publishQuestion({
            etest_class: this.dataTable.header.etest_class,
            question_selected: this.radioGroup
          });
          if (response.data.code) {
            const d = response.data.data;
            this.$socket.emit("serverNotif", {
              recipient: d.recipient,
              data: d
            });
          }
        } else {
          response = await unpublishQuestion({
            etest_class: this.dataTable.header.etest_class
          });
        }
        if (response.data.code) {
          this.snackBar(true, this.$i18n.t(msg));
          this.eTestLesson();
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.loadingConfirm = false;
        this.loadingPublish = false;
        console.error("savePublish()\n", error);
        this.snackBar(false, this.$i18n.t("app.there_is_an_error"));
      }
      this.loadingConfirm = false;
      this.loadingPublish = false;
      this.modalConfirm = false;
      this.dialogPublish = false;
      this.radioGroup = 1;
    },
    infoButton() {
      let result = { icon: "", name: "" };
      if (this.dataTable.header.status === "PUBLISH")
        result = {
          icon: "mdi-close-circle-outline",
          name: `${this.$i18n.t("app.cancel")} Publish`
        };
      else
        result = {
          icon: "mdi-checkbox-marked-circle-outline",
          name: "Publish"
        };
      return result;
    },
    async saveKD(idQuestionBank) {
      this.table.loading = true;
      const body = { kd: this.modelKD, id: idQuestionBank };
      setQuestionBankKD(body)
        .then(res => {
          if (res.data.code)
            this.snackBar(true, this.$i18n.t("e_test.msg.success_update_kd"));
          else this.snackBar(false, res.data.message);
          this.modelKD = 0;
          this.eTestLesson();
        })
        .catch(() => {
          this.table.loading = false;
          this.modelKD = 0;
        });
    },
    async getCompetencies(lesson) {
      if (lesson) {
        this.loadingKD = true;
        try {
          const response = await listCompetenciesByLessonDetail({
            lesson: lesson
          });
          if (response.data.code === 1) {
            this.dataKD = response.data.data;
          } else {
            this.snackBar(false, response.data.message);
          }
        } catch (error) {
          console.error("getCompetencies()\n", error);
          this.snackBar(false, error);
        }
        this.loadingKD = false;
      }
    },
    getKD(idKD) {
      const kd = this.dataKD.find(kd => kd.id === idKD);
      if (kd) return `${kd.core_competencies} - ${kd.kd_chapter}`;
    },
    viewImage(event) {
      if (event.target.src) {
        this.viewFile = true;
        this.urlFile = event.target.src;
      }
    },
    validateImage: name => isImageFile(name),
    dialogConfirm(item) {
      if (this.multipleQuestion.length > 0) {
        this.listQuestionId = this.multipleQuestion.map(item => item.id);
      } else {
        this.listQuestionId = [item.id];
      }
      this.modelDialogConfirm = true;
    },
    async removeQuestionBank() {
      this.loadingDelete = true;
      try {
        const response = await removeQuestion({ id: this.listQuestionId });
        if (response.data.code) {
          this.snackBar(
            true,
            this.$i18n.t("e_test.msg_success_delete_question_bank")
          );
          this.eTestLesson();
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        console.error("removeQuestionBank()\n", error);
        this.loadingDelete = false;
      }
      this.listQuestionId = [];
      this.multipleQuestion = [];
      this.modelDialogConfirm = false;
      this.loadingDelete = false;
    },
    truncateHtml: html =>
      truncate(html, 10, { byWords: true, stripTags: true }),
    toggleDialogCopy() {
      this.dialogCopy = !this.dialogCopy;
    },
    async savePoint(id) {
      const body = { id: id, point: this.point };
      if (this.$refs.weight.validate()) {
        try {
          const response = await updateWeightLocal(body);
          if (response.data.code) {
            this.snackBar(
              true,
              this.$i18n.t("e_test.msg.success_update_weight")
            );
            this.eTestLesson();
          } else {
            this.snackBar(false, response.data.message);
          }
          this.resetWeight();
        } catch (error) {
          console.error("savePoint()\n", error);
          this.point = null;
          this.table.loading = false;
        }
        this.table.loading = false;
      }
    },
    resetWeight() {
      this.$refs.weight.reset();
      this.$refs.weight.resetValidation();
    },
    paginateLimit(page, limit) {
      this.table.page = page;
      this.table.limit = limit;
      this.eTestLesson();
    },
    handleSearch(value) {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.table.search = value;
        this.eTestLesson();
      }, doneTypingInterval);
    },
    arrowPage(page) {
      this.table.page = page;
      this.eTestLesson();
    },
    async questionPriority(item) {
      this.table.loading = true;
      this.disableStar = true;
      try {
        const response = await updateMainQuestion({
          id: item.id,
          main: item.main ? 0 : 1
        });
        if (response.data.code) {
          this.snackBar(true, this.$i18n.t("e_test.msg_type"));
          this.eTestLesson();
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        console.error("questionPriority()\n", error);
        this.table.loading = false;
        this.disableStar = false;
      }
      this.table.loading = false;
      this.disableStar = false;
    },
    toggleAddManual() {
      this.addManual = !this.addManual;
      if (!this.addManual) {
        this.eTestLesson();
      }
    },
    // toggleDialogEntry() {
    //   this.dialogEntry = !this.dialogEntry;
    //   if (!this.dialogEntry) {
    //     this.eTestLesson();
    //   }
    // },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    async eTestLesson() {
      this.table.loading = true;
      const body = {
        id: this.$route.params.id,
        limit: this.table.limit,
        page: this.table.page,
        search: this.table.search
      };
      try {
        const response = await eTestLessonList(body);
        if (response.data.code) {
          this.dataTable = response.data.data;
          this.getCompetencies(this.dataTable.header.lesson);
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        console.error("eTestLesson()\n");
        this.table.loading = false;
      }
      this.table.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.fab {
  position: absolute;
  bottom: 0;
  right: 0;
}
.cursor-star {
  cursor: pointer;
}
</style>
